import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header align-items-center border-0 mt-4" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "fw-bold mb-2 text-dark" }
const _hoisted_4 = { class: "text-muted fw-semobold fs-7" }
const _hoisted_5 = { class: "mt-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card", _ctx.widgetClasses])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.widgetTitle), 1),
        _createElementVNode("span", _hoisted_4, "Total Deals : " + _toDisplayString(_ctx.sum), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_apexchart, {
          class: "",
          type: "pie",
          width: "380",
          options: _ctx.chartOptions,
          series: _ctx.series
        }, null, 8, ["options", "series"])
      ])
    ])
  ], 2))
}