
import { defineComponent, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "main-dashboard",
  components: {
    ListWidget5,
  },
  setup() {
    const store = useStore();

    let full_stats = computed(() => {
      return store.getters.currentStats;
    });

    let labels = []
    
    let series = ref([]);
    let sum = ref(0);
    let chartOptions =  ref({
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    });
    watch(full_stats,() => {
      console.log("stats : ",full_stats.value)
      let stats = []
      stats = full_stats.value.filter(stat => stat.type === 'deals');
      for(var i = 0 ; i < stats.length ; i++){
        labels[i] = stats[i]['label'];
        series.value[i] = stats[i]['value']
      }
      sum.value = series.value.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0)
      chartOptions.value.labels = labels;
    //Next KPI

    });
    onMounted(async () => {
      await store.dispatch(Actions.GET_STATS);
    });
    return {
      series,
      chartOptions,
      sum
    }
  },
});
