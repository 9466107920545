
import { defineComponent } from "vue";
export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
    chartOptions: Array,
    series: Array,
    widgetTitle: String,
    sum: Number,

  },
});
